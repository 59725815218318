
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { headerWidthFluid } from "@/core/helpers/config";
import { headerFixed, headerFixedOnMobile } from "@/core/helpers/config";

export default defineComponent({
  name: "NMHeader",
  props: {
    title: String,
    breadcrumbs: Array,
  },
  components: {},
  setup() {
    const store = useStore();

    const webURL = process.env.VUE_APP_WEB_URL;
    const appName = process.env.VUE_APP_NAME;

    const currentUser = computed(() => {
      return store.getters.currentUser;
    });

    const isHeaderSticky = computed(() => {
      if (window.innerWidth > 768) {
        return headerFixed.value;
      } else {
        return headerFixedOnMobile.value;
      }
    });

    return {
      headerWidthFluid,
      isHeaderSticky,
      webURL,
      appName,
      currentUser,
      publicPath: process.env.BASE_URL,
    };
  },
});
