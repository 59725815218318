import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTLoader = _resolveComponent("KTLoader")!
  const _component_NMHeader = _resolveComponent("NMHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_NMFooter = _resolveComponent("NMFooter")!
  const _component_KTScrollTop = _resolveComponent("KTScrollTop")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaderEnabled)
      ? (_openBlock(), _createBlock(_component_KTLoader, {
          key: 0,
          logo: _ctx.loaderLogo
        }, null, 8, ["logo"]))
      : _createCommentVNode("", true),
    _createVNode(_component_NMHeader, {
      title: _ctx.pageTitle,
      breadcrumbs: _ctx.breadcrumbs
    }, null, 8, ["title", "breadcrumbs"]),
    _createVNode(_component_router_view),
    _createVNode(_component_NMFooter),
    _createVNode(_component_KTScrollTop)
  ], 64))
}